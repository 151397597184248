import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import {Link} from 'gatsby'

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { APP_URI } from '../configs';

import ilustration from '../images/ilustration.jpg'
import  clips from '../images/clips-icon.svg'
import  clipsYellow from '../images/clips-icon-yellow.svg'

import  cup from '../images/cup-icon.svg'
import  cupYellow from '../images/cup-icon-yellow.svg'

import  checked from '../images/has-feature-icon.svg'
import  noChecked from '../images/hasnt-feature-icon.svg'

import  plan1 from '../images/plan-1.png'
import  plan2 from '../images/plan-2.png'
import  plan3 from '../images/plan-3.png'

import '../scss/troop-pro.scss'

export default class TroopPro extends React.Component {
  constructor(props) {
    super(props);
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    new WOW.WOW({
        live: false,
        offset: 500
    }).init();
  }

  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  render(){
    return(
    <div className="troop-pro">
          <div className="content troop-pro-content">
      <Topbar handlerNavigation={(ref) => this.scrollToReference(ref)} pageReferences={this.references}/>
      <Helmet>

      </Helmet>

      <section ref={this.references.hero} className="hero--section">
        <div className="wrapper">
          <div className="flex--container">
            <h1 className="hero--title">Potenciate con PRO</h1>
            <p className="hero--text">
            Llevá tu Troop a otro nivel y disfrutá de los mejores beneficios de <span> ser un trooper PRO. </span>
            </p>
          </div>
        </div>
      </section>

      <section ref={this.references.features} className="pricing--section">
        <div className="wrapper">
        <h2 className="pricing--title">¿Qué beneficios tenés con Troop PRO?</h2>
          <div className="pricing--list">
            <div className="pricing-item pricing-1">
                <ol className="item--list">
                  <p className="pricing--type">
                    FREE
                  </p>
                  <li className="list--item title">
                    <div className="item--icon">
                    <img src={clips} />
                    </div>
                    <p className="item--text title">
                    Clips
                    </p>
                  </li> 
                 <li className="list--item">
                    <div className="item--icon">
                    <img src={noChecked} />
                    </div>
                    <p className="item--text">
                    Edición ilimitada
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Transiciones estándar
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Calidad en 720p a 30fps
                    </p>
                  </li>
                  <li className="list--item title">
                    <div className="item--icon">
                    <img src={cup} />
                    </div>
                    <p className="item--text title">
                    Competencias
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Torneos de acceso público
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Insignias de desafíos en tu perfil
                    </p>
                  </li>
                  <li className="list--item title">
                    <p className="item--text title">
                    Otros
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={noChecked} />
                    </div>
                    <p className="item--text">
                    Plataforma libre de anuncios
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Fondos estándar para tu perfil
                    </p>
                  </li>

                </ol>
            </div>
            <div className="pricing-item pricing-2">
                <ol className="item--list">
                <p className="pricing--type">
                    FREE
                  </p>
                  <li className="list--item title">
                    <div className="item--icon">
                    <img src={clipsYellow} />
                    </div>
                    <p className="item--text title">
                    Clips
                    </p>
                  </li> 
                 <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Edición ilimitada
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Transiciones especiales
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Calidad en 1080p a 60fps
                    </p>
                  </li>
                  <li className="list--item title">
                    <div className="item--icon">
                    <img src={cupYellow} />
                    </div>
                    <p className="item--text title">
                    Competencias
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Torneos exclusivos para Troop PRO
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Insignias PRO de desafíos en tu perfil
                    </p>
                  </li>
                  <li className="list--item title">
                    <p className="item--text title">
                    Otros
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Plataforma libre de anuncios
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Suscripción a WTFast o Exit Lag (según la duración de tu plan)
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={checked} />
                    </div>
                    <p className="item--text">
                    Fondos PRO para tu perfil
                    </p>
                  </li>
                </ol>
            </div>
            </div>
       </div>
       </section>


      <section ref={this.references.features} className="plans--section">
        <div className="wrapper">
        <h2 className="plans--title">Elegí el plan para vos</h2>
          <div className="plans--list">
            <div className="plans-item plan-1">
                <ol className="item--list">
                  <h3 className="plan--title">Mensual</h3>
                  <img src={plan1} className="plan--image"/>
                  <h3 className="plan--price">$100<span>/mes</span></h3>
                  <Link to='/upgrade?checkout' className="button--global yellow">Hacete PRO</Link>
                </ol>
            </div>
            <div className="plans-item plan-2">
                <ol className="item--list">
                  <p className="plan--type">
                  -20%
                  </p>
                  <h3 className="plan--title">Semestral</h3>
                  <img src={plan2} className="plan--image"/>
                  <h3 className="plan--price">$80<span>/mes</span></h3>
                  <Link to='/upgrade?checkout' className="button--global yellow">Hacete PRO</Link>
                </ol>
            </div>
            <div className="plans-item plan-3">
                <ol className="item--list">
                  <p className="plan--type">
                  -35%
                  </p>
                  <h3 className="plan--title">Anual</h3>
                  <img src={plan3} className="plan--image"/>
                  <h3 className="plan--price">$65<span>/mes</span></h3>
                  <Link to='/upgrade?checkout' className="button--global yellow">Hacete PRO</Link>
                </ol>
            </div>
           </div>
           <p className="checkout">
            ¿Tenés dudas sobre los planes y cobros? <br/>
            Accedé a nuestro portal de <span><Link to={'/preguntas-frecuentes'}> Preguntas Frecuentes </Link></span>
            </p>
       </div>
       </section>
    
     <Footer/>
    </div>
    </div>
    );
  }
}
